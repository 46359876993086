<template>
  <div id="app">
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">The Procrastinator-inator! &trade;</p>
        <p class="subtitle">
          Why do today what you can put off until tomorrow?
        </p>
      </div>
    </section>

    <div class="container">
      <section class="section">
        <p class="block">
          Welcome to our AI-powered procrastination tool,
          <span class="has-text-primary"
            ><b>The Procrastinator-inator! &trade;</b></span
          >
          Enter a task you've been putting off and let our algorithm generate a
          list of creative and convincing reasons to procrastinate. With our
          tool, you'll be able to delay even the most urgent of tasks with ease.
          Try it now and see how long you can put off getting things done!"
        </p>
        <div class="block">
          <div class="field">
            <label class="label"
              >What is it that you want to procrastinate?</label
            >
            <div class="control">
              <input
                v-model="task"
                placeholder="example: taking out the trash"
                class="input"
                type="text"
              />
            </div>
          </div>

          <div class="field">
            <label class="checkbox">
              <input v-model="is_funny" type="checkbox" />
              Make me laugh (highly suggested).
            </label>
          </div>

          <div class="field">
            <button
              @click="runQuery"
              class="button is-primary"
              :class="button_class"
            >
              Give me an excuse!
            </button>
          </div>
        </div>

        <div class="card block" v-if="answer">
          <header class="card-header">
            <p class="card-header-title">Your Excuse</p>
          </header>
          <div class="card-content">
            <p>
              {{ answer }}
            </p>
          </div>
        </div>
        <div class="block">
          <p>
            <b>{{ task_count }}</b> tasks delayed since {{ start_time }}
          </p>
        </div>
      </section>
    </div>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>Don't blame me. It was my son's idea.</p>
        <p>
          <strong>The Procrastinator-inator &trade;</strong> by Randall Smith.
          &copy; 2023
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref } from "vue"

const task = ref("")
const is_funny = ref(true)
const query_running = ref(false)
const answer = ref("")
const button_class = ref("")
const start_time = ref(null)
const task_count = ref(0)

async function ask(question) {
  const url = process.env.VUE_APP_API_URL + "/ask"
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ question: question })
  }
  const response = await fetch(url, settings)
  console.log(response)
  const data = await response.json()
  return data.answer
}

async function runQuery() {
  answer.value = ""
  let question
  if (is_funny.value) {
    question = "Give me a funny reason to procrastinate: " + task.value
  } else {
    question = "Give me a reason to procrastinate: " + task.value
  }
  query_running.value = true
  button_class.value = "is-loading"
  try {
    answer.value = await ask(question)
  } catch (error) {
    console.log(error)
  }
  query_running.value = false
  button_class.value = ""
  // update the status
  await getStatus()
}

async function getStatus() {
  const url = process.env.VUE_APP_API_URL + "/status"
  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }
  const response = await fetch(url, settings)
  console.log(response)
  const data = await response.json()
  task_count.value = data.request_count
  start_time.value = new Date(data.start_time)
}

getStatus()
</script>
